<template>
  <div>
    <f-default-header
      :title="$t('pages.pos.title')"
      :subtitle="$t('pages.pos.subtitle')"
    />
    <!-- <a href="javascript:void(0);" @click="testeNotifications">teste pos notifications</a> # sometimes try two times bcs first settings thing<br>
    <a href="http://localhost:8025/" target="_blank">MailHog</a> -->

    <f-filter store="pos" @change="getData" />
    <f-list />

    <f-actions-btn-holder>
      <v-btn
        color="primary"
        data-testid="pos-btn-new"
        elevation="0"
        large
        @click="create"
      >
        {{ $t("labels.btnNewSend") }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FList from "../../components/pos/List.vue";
import FFilter from "../../components/shared/ListFilter";

export default {
  components: {
    FList,
    FFilter,
  },
  methods: {
    create() {
      this.$router.push({
        name: "pos-upload",
      });
    },
    getData() {
      this.$store.dispatch("pos/list");
    },
    testeNotifications() {
      fetch("http://localhost:3000/pos/email");
    },
  },
};
</script>
