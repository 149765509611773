<template>
	<div class="list-filter">
		<div class="btn-align" :class="{ open: isFilterOpen }">
			<button @click="filterToggle">
				<v-icon>mdi-filter</v-icon>
			</button>
		</div>

		<f-expandable-div :expanded="isFilterOpen">
			<f-card>
				<div>
					<h1>{{ $t("pages.general.listFilter.filters") }}</h1>

					<h2>{{ $t("pages.general.listFilter.date") }}</h2>
					<hr />

					<v-row>
						<v-col cols="5">
							<v-select
								v-model="listArgs.date.month"
								:label="$t('pages.general.listFilter.selectMonth')"
								:items="months" />
						</v-col>
						<v-col cols="5">
							<v-select
								v-model="listArgs.date.year"
								:label="$t('pages.general.listFilter.selectYear')"
								:items="years"
								:disabled="disableYear" />
						</v-col>
						<v-col cols="2" class="clr-btn">
							<v-btn elevation="0" @click="clearDate">
								{{ $t("pages.general.listFilter.clear") }}
							</v-btn>
						</v-col>
					</v-row>

					<div v-if="isAdmin">
						<h2>{{ $t("pages.general.listFilter.distributor") }}</h2>
						<hr />

						<v-row>
							<v-col cols="10">
								<v-select
									v-model="listArgs.distributor"
									:label="$t('pages.general.listFilter.selectDist')"
									:items="distributors" />
							</v-col>
							<v-col cols="2" class="clr-btn">
								<v-btn elevation="0" @click="clearDistributor">
									{{ $t("pages.general.listFilter.clear") }}
								</v-btn>
							</v-col>
						</v-row>
					</div>

					<div class="btn-align margin">
						<v-btn elevation="0" color="primary" @click="apply">
							{{ $t("pages.general.listFilter.apply") }}
						</v-btn>
					</div>
				</div>
			</f-card>
		</f-expandable-div>
	</div>
</template>

<script>
export default {
	props: ["store"],
	data: () => ({
		months: [
			{ text: "Janeiro", value: 1 },
			{ text: "Fevereiro", value: 2 },
			{ text: "Março", value: 3 },
			{ text: "Abril", value: 4 },
			{ text: "Maio", value: 5 },
			{ text: "Junho", value: 6 },
			{ text: "Julho", value: 7 },
			{ text: "Agosto", value: 8 },
			{ text: "Setembro", value: 9 },
			{ text: "Outubro", value: 10 },
			{ text: "Novembro", value: 11 },
			{ text: "Dezembro", value: 12 },
		],
		years: [],
		isFilterOpen: false,
	}),
	computed: {
		distributors() {
			return this.$store.state.distributor.list.map((item) => ({
				text: item.name,
				value: item.id,
			}));
		},
		listArgs() {
			return this.$store.state[this.$props.store].listArgs;
		},
		disableYear() {
			return this.listArgs.date.month == 0;
		},
		isAdmin() {
			var isAdmin = true;

			if (this.$store.state.auth.user.distributorID) {
				return false;
			}

			return isAdmin;
		},
	},
	watch: {
		listArgs: {
			handler: function (_) {
				this.$emit("change", null);
			},
			deep: true,
		},
	},
	created() {
		const currentYear = new Date().getFullYear();
		for (var i = 2022; i <= currentYear; i++) {
			this.years.push(i);
		}

		this.$store.dispatch("distributor/list");
	},
	methods: {
		filterToggle() {
			this.isFilterOpen = !this.isFilterOpen;
		},
		clearDate() {
			this.$store.state[this.$props.store].listArgs.date.month = 0;
			this.$store.state[this.$props.store].listArgs.date.year = 0;
		},
		clearDistributor() {
			this.$store.state[this.$props.store].listArgs.distributor = 0;
		},
		apply() {
			this.isFilterOpen = false;
		},
	},
};
</script>
